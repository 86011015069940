<template>
 <div id="app">
  {{ info }}
</div>
</template>

<script>
import Vue from 'vue';
// Ajax通信ライブラリ
import axios from 'axios';

new Vue({
  el: '#app',
  data () {
    return {
      info: null
    }
  },
  mounted () {
    axios
      .get('https://us-central1-opnecoupon-dev.cloudfunctions.net/app/api/getCouponList/1234')
      .then(response => (this.info = response))
  }
})
</script>